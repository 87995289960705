<template>
  <div class="reports">
    <Users />
  </div>
</template>

<script>
import Users from '@/components/users/Users.vue'
export default {
  components: {
    Users
  }
}
</script>
