<template>
  <v-sheet
    v-if="showUserForm"
    width="95%"
    height="95%"
    :elevation="4"
    color="rgba(120,120,120,0.5)"
    :tile="false"
    style="margin: auto auto 20px auto;"
  >
    <v-simple-table
      fixed-header height="100%"
      style=" margin: auto;"
    >
      <thead>
      <tr>
        <th scope="col" style="width: 36%; font-size: 22px;">Email</th>
        <th scope="col" style="width: 22%; font-size: 22px;">Password</th>
        <th scope="col" style="width: 22%; font-size: 22px;">Role</th>
        <th scope="col" style="width: 20%; font-size: 22px;"> Save / Cancel  </th>
      </tr>
      </thead>
  <transition name="fade">
    <tr v-if="showUserForm">
      <td style="display: flex; justify-content: center; align-items: center">
        <v-text-field
          v-model="newUser.email"
          autocomplete="off"
          @keydown="clearError('email')"
          :rules="[dbError]"
          label="Account Name"
          placeholder="Type Here"
          style="width: 40%; margin: auto;"
        >
        </v-text-field>
        <span>@</span>
        <v-select
            v-model="newUser.domain"
            :items="availDomains"

            label="Domains"
            dense
            solo
            hide-details="auto"
            style="width: 40%; margin: auto; margin-left: 10px; font-size: 12px; overflow: hidden; text-overflow: ellipsis !important;"
        ></v-select>
      </td>
      <td>
        <v-text-field
          v-model="newUser.password"
          label="Password"
          placeholder="Enter Password"
          :type="newUser.passwordVisible"
          autocomplete="off"
          loading
          required
          :append-icon="newUser.passwordVisible !== 'password' ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="switchVisibility"
        >
          <template v-slot:progress>
            <v-progress-linear
              v-if="true"
              :value="passwordProgress"
              color="info"
              absolute
              height="3"
            ></v-progress-linear>
          </template>
        </v-text-field>

      </td>
      <td style="padding: 1%">
        <v-select
          v-model="newUser.role"
          :items="availRoles"
          label="Roles"
          dense
          solo
          hide-details="auto"
          style="text-overflow: ellipsis !important;"
        ></v-select>
      </td>
      <td>
        <v-btn
            small
          class="ma-2"
          :loading="loading.includes('newUser')"
          :disabled="!formVerified"
          color="info"
          @click="createNewUser()"
          @keydown.enter="createNewUser()"
        >
          Save

        </v-btn>
        <v-btn
            small
          class="ma-2"
          color="warning"
          @click="toggleUserForm()"
          @keydown.enter="toggleUserForm()"
        >
          Cancel
        </v-btn>
      </td>
    </tr>
  </transition>
    </v-simple-table>
  </v-sheet>
</template>

<script>

import { cloneDeep } from 'lodash'

export default {
  name: 'NewUserForm',
  props: {
    showUserForm: { type: Boolean },
    newUser: { type: Object },
    clearError: { type: Function },
    switchVisibility: { type: Function },
    items: { type: Array },
    loading: { type: Array },
    formVerified: { type: Boolean },
    createNewUser: { type: Function },
    toggleUserForm: { type: Function },
    authDomains: { type: Object }
  },
  computed: {
    availDomains () {
      let authDomains = cloneDeep(this.authDomains)
      let currAuthDomains = []
      if (typeof this.newUser.role === 'string' && this.newUser.role.includes('CUSTOMER')) {
        currAuthDomains = Object.values(authDomains['CUSTOMER'])
      } else if (typeof this.newUser.role === 'string' && this.newUser.role.includes('FULFILLER')) {
        currAuthDomains = Object.values(authDomains['FULFILLER'])
      } else {
        currAuthDomains = authDomains['FULFILLER'] ? Object.values(authDomains['FULFILLER']) : currAuthDomains
        currAuthDomains = [...currAuthDomains, ...(Object.values(authDomains['CUSTOMER']))]
      }
      return currAuthDomains.flat()
    },
    availRoles () {
      let currRoles = cloneDeep(this.items)
      if (this.newUser.domain) {
        let isCustomer = Object.keys(this.authDomains['CUSTOMER']).some(item => this.authDomains['CUSTOMER'][item].includes(this.newUser.domain.toString()))
        currRoles = currRoles.filter(role => isCustomer ? role.value.includes('CUSTOMER') : !role.value.includes('CUSTOMER'))
      }
      return currRoles
    },
    dbError () {
      return !this.newUser.errors.includes('email') || 'Email Already in Use'
    },
    passwordProgress () {
      return Math.min(100, this.newUser.password.length * 10)
    }
  }
}
</script>
