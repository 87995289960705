export const UserTypes = {
  SUPERADMIN: 'Super Admin',
  PARTNER_ADMIN: 'Partner Admin',
  PARTNER_USER: 'Partner User',
  FULFILLER_ADMIN: 'Fulfiller Admin',
  FULFILLER_USER: 'Fulfiller User',
  CUSTOMER_ADMIN: 'Customer Admin',
  CUSTOMER_USER: 'Customer User',
  USER: 'User'
}

export const UserLevels = {
  SUPERADMIN: 10,
  PARTNER_ADMIN: 11,
  PARTNER_USER: 11,
  FULFILLER_ADMIN: 6,
  FULFILLER_USER: 5,
  CUSTOMER_ADMIN: 4,
  CUSTOMER_USER: 3
}

export const roleAbility = {
  SUPERADMIN: ['CUSTOMER_USER', 'CUSTOMER_ADMIN', 'FULFILLER_USER', 'FULFILLER_ADMIN', 'USER'],
  PARTNER_ADMIN: ['PARTNER_ADMIN', 'PARTNER_USER'],
  PARTNER_USER: ['PARTNER_ADMIN', 'PARTNER_USER'],
  FULFILLER_ADMIN: ['CUSTOMER_USER', 'CUSTOMER_ADMIN', 'FULFILLER_USER', 'FULFILLER_ADMIN', 'USER'],
  FULFILLER_USER: ['CUSTOMER_USER', 'CUSTOMER_ADMIN', 'USER'],
  CUSTOMER_ADMIN: ['CUSTOMER_USER', 'CUSTOMER_ADMIN', 'USER'],
  CUSTOMER_USER: [],
  USER: []
}

export const filterOptions = [
  { value: 'CUSTOMER_USER', text: 'Customer User' },
  { value: 'CUSTOMER_ADMIN', text: 'Customer Admin' },
  { value: 'FULFILLER_USER', text: 'Fulfiller User' },
  { value: 'FULFILLER_ADMIN', text: 'Fulfiller Admin' },
  { value: 'PARTNER_USER', text: 'Partner User' },
  { value: 'PARTNER_ADMIN', text: 'Partner Admin' },
  { value: 'SUPERADMIN', text: 'Super-Admin' }
]

export const convertedUser = {
  SUPERADMIN: [{ value: 'FULFILLER_USER', text: 'Fulfiller User' }, { value: 'FULFILLER_ADMIN', text: 'Fulfiller Admin' }],
  PARTNER_ADMIN: [{ value: 'PARTNER_USER', text: 'Partner User' }, { value: 'PARTNER_ADMIN', text: 'Partner Admin' }],
  PARTNER_USER: [{ value: 'PARTNER_USER', text: 'Partner User' }, { value: 'PARTNER_ADMIN', text: 'Partner Admin' }],
  FULFILLER_ADMIN: [{ value: 'FULFILLER_USER', text: 'Fulfiller User' }, { value: 'FULFILLER_ADMIN', text: 'Fulfiller Admin' }],
  FULFILLER_USER: [{ value: 'FULFILLER_USER', text: 'Fulfiller User' }, { value: 'FULFILLER_ADMIN', text: 'Fulfiller Admin' }],
  CUSTOMER_ADMIN: [{ value: 'CUSTOMER_USER', text: 'Customer User' }, { value: 'CUSTOMER_ADMIN', text: 'Customer Admin' }],
  CUSTOMER_USER: [{ value: 'CUSTOMER_USER', text: 'Customer User' }, { value: 'CUSTOMER_ADMIN', text: 'Customer Admin' }]
}
