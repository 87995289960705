<template>
  <div >
    <h2 class="text-left mb-4 mt-4 mid-space-grid " >
      <span> {{$t('features.users')}}</span>
      <span style="display: flex; justify-content: flex-end; margin-right: 10px;">
        <v-select
          v-if="myInfo.roles.some(item => item.includes('SUPERADMIN'))"
          :items="filterableRoles"
          label="Filter By Role Type"
          dense
          solo
          item-disabled="disabled"
          return-object
          :multiple="true"
          hide-details="auto"
          style="min-width: 180px; max-width: 360px;"
          v-on:input="e => filterSelect(e)"
        ></v-select>
      </span>
      <transition name="fade">
        <button v-if="!restrictedAccess && !showUserForm" type="button" class="btn btn-warning justify-end" style="color: white; font-weight: bold;" @click="toggleUserForm()">
          New User
        </button>
      </transition>
    </h2>
        <NewUserForm
          :showUserForm="showUserForm"
          :newUser="newUser"
          :clearError="clearError"
          :switchVisibility="switchVisibility"
          :items="items"
          :loading="loading"
          :formVerified="formVerified"
          :createNewUser="createNewUserFunc"
          :toggleUserForm="toggleUserForm"
          :authDomains="authDomains"
        />
    <v-sheet
      width="95%"
      height="95%"
      :elevation="4"
      color="rgba(120,120,120,0.5)"
      :tile="false"
      style="margin: auto;"
    >
    <v-simple-table
      fixed-header height="100%"
      style=" margin: auto;"
    >
      <thead>
      <tr>
        <th scope="col" style="width: 22%; font-size: 22px;">Email</th>
        <th scope="col" style="width: 22%; font-size: 22px;">Password</th>
        <th scope="col" style="width: 18%; font-size: 22px;">Role</th>
        <th scope="col" style="width: 19%; font-size: 22px;">Account</th>
        <th scope="col" style="width: 19%; font-size: 22px;"></th>
      </tr>
      </thead>
      <tbody style="text-align: left;">

        <tr v-for="skel in tableLoading" :key="skel">
          <UserTableSkeleton v-for="item in [1,2,3,4]" :key="item + '-Skeleton'" />
        </tr>
      <tr v-for="(row, ind) in sortedRows" :key="row.uid">
        <td v-if="ind === editingRow && row.uid === myInfo.id && false">
          <v-text-field
          v-model="row.email"
          autocomplete="off"
          @keydown="clearError('email')"
          :rules="[dbError]"
          succ
          type="email"
          label="Email"
          placeholder="Enter Email"
          >
          </v-text-field>

        </td>
        <td v-else>
          <span>
          {{ row.email}}
            <span v-if="row.uid === myInfo.id" style="margin-left: 8px; ">
              <Icon
                iconName="user-check"
                :is-bootstrap="false"
                size="6px"
                color="grey"
              />
            </span>
          </span>
        </td>
        <td v-if="ind === editingRow && row.uid === myInfo.id">
          <span>
          <span class="table-cell-double-input">
            <v-text-field
              v-model="currUserPw.old"
              label="Enter Old Password"
              placeholder="Old Password"
              :type="currUserPwVisible.old"
              autocomplete="off"
              small
              required
              :append-icon="currUserPwVisible.old !== 'password' ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="switchCurrUserVisibilityOld"
            >

            </v-text-field>
          </span>
          <span class="table-cell-double-input">
            <v-text-field
              v-model="currUserPw.new"
              label="Enter New Password"
              placeholder="New Password"
              :type="currUserPwVisible.new"
              autocomplete="off"
              loading
              small
              required
              :append-icon="currUserPwVisible.new !== 'password' ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="switchCurrUserVisibilityNew"
            >
              <template v-slot:progress>
                <v-progress-linear
                  v-if="true"
                  :value="currUserPwProg.new"
                  color="info"
                  absolute
                  height="3"
                ></v-progress-linear>
              </template>
            </v-text-field>
          </span>
          </span>
        </td>
        <td v-else>
          {{ row.password}}
        </td>
        <td v-if="ind === editingRow && row.uid !== myInfo.id" style="padding: 1% 1% 0;" >
            <v-select
              :value="row.role"
              style="padding-bottom: 0px; text-overflow: ellipsis !important;"
              :items="getAvailableOptions(row)"
              label="Roles"
              dense
              solo
              v-on:input="(e) => getSelectedItem(e, ind)"
            ></v-select>
        </td>
        <td v-else style="padding: 1%; padding-bottom: 1%;">
          {{ row.role.text}}
        </td>
        <td>
          {{row.account}}
        </td>
        <td>
          <UserCrudBtns
            :ind="ind"
            :updateUser="updateUser"
            :editRow="editRow"
            :trashRow="trashRow"
            :editingRow="editingRow"
            :loading="loading"
            v-if="hasAbilityToEdit(row) || row.uid === myInfo.id"
            :trashability="row.uid !== myInfo.id"
          />
        </td>
      </tr>
      </tbody>
    </v-simple-table>
    </v-sheet>
  </div>
</template>

<script>
import UserCrudBtns from '@/components/users/UserCrudBtns'
import UserTableSkeleton from '@/components/users/UserTableSkeleton'
import NewUserForm from '@/components/users/NewUserForm'
import { mapGetters, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import { UserTypes, roleAbility, UserLevels, convertedUser, filterOptions } from '@/utils/constants'
import {
  getContextsUsers,
  createNewUser,
  adjustUserPermission,
  trashUser,
  putContextsUser,
  getAuthDomainNames
} from '@/api/users'
import Icon from '@/components/elements/Icon'

export default {
  name: 'Users',
  components: {
    Icon,
    UserCrudBtns,
    NewUserForm,
    UserTableSkeleton
  },
  data: () => ({
    header: [],
    tableLoading: [1, 2, 3, 4],
    userList: [],
    rows: [],
    unfilteredRows: [],
    items: [{}, {}, {}, {}],
    newUser: { email: '', domain: '', password: '', role: {}, errors: [], passwordVisible: 'password' },
    authDomains: {},
    editingRow: null,
    showUserForm: false,
    loading: [],
    currUserPwVisible: { new: 'password', old: 'password' },
    currUserPw: { new: '', old: '' },
    allAuthorities: {},
    emailRules: {

      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      }
    }
  }),
  computed: {
    filterableRoles () {
      return filterOptions
    },
    sortedRows () {
      const allRows = cloneDeep(this.rows)
      const sortedRows = allRows.sort((x, y) => (x.email > y.email) ? 1 : ((y.email > x.email) ? -1 : 0))
      const selfToFront = sortedRows.sort((x, y) => x.uid === this.myInfo.id ? -1 : y.uid === this.myInfo.id ? 1 : 0)
      return selfToFront
    },
    dbError () {
      return !this.newUser.errors.includes('email') || 'Email Already in Use'
    },
    passwordProgress () {
      return Math.min(100, this.newUser.password.length * 10)
    },
    currUserPwProg () {
      return {
        new: Math.min(100, this.currUserPw.new.length * 10),
        old: Math.min(100, this.currUserPw.old.length * 10)
      }
    },
    restrictedAccess () {
      const roles = ['SUPERADMIN', 'FULFILLER_ADMIN', 'FULFILLER_USER', 'CUSTOMER_ADMIN']
      return !roles.some(r => this.myInfo.roles.includes(r))
    },
    ...mapGetters({
      isAdmin: 'login/isAdmin',
      myInfo: 'login/myInfo',
      myFulfillers: 'login/myFulfillers',
      myCustomers: 'login/myCustomers',
      getMyIds: 'login/getMyIds',
      myAuthority: 'login/myAuthority'
    }),
    formVerified () {
      return !!(this.newUser.email && this.newUser.password && this.newUser.role)
    }
  },
  async mounted  () {
    this.getAvailableRoles()
    await this.handleAuthDomains()
    // Set user list
    const getUsers = await getContextsUsers()
    if (this.restrictedAccess) {
      this.userList = getUsers.filter(u => this.myInfo.id === u.id)
    } else {
      this.userList = getUsers
    }
    this.userList = this.userList.sort((x, y) => (x.username > y.username) ? 1 : ((y.username > x.username) ? -1 : 0))
    // Simulate Loading Period
    setTimeout(() => {
      this.rows = []
      this.unfilteredRow = []
      for (const user of this.userList) {
        const roleIndex = user.roles.length - 1
        this.addUserToTable(user, roleIndex)
      }
      this.tableLoading = []
    }, 500)
  },
  methods: {
    ...mapActions({
      getAuthorityName: 'login/getAuthorityName'
    }),
    filterSelect (roleTypeArray) {
      const allRows = cloneDeep(this.unfilteredRows)
      console.log('filter select', roleTypeArray, allRows)
      this.rows = roleTypeArray.length > 0 ? allRows.filter(row => roleTypeArray.some(el => el.value === row.role.value)) : this.unfilteredRows
    },
    getAccountName (user, roleIndex) {
      const userAccount = user.roles[roleIndex].split('_')[0]
      let myAccount = []
      if (userAccount === 'SUPERADMIN') {
        myAccount.FULFILLER = [{ name: userAccount }]
      } else {
        myAccount.FULFILLER = this.myFulfillers.filter(fulf => user.authorities?.FULFILLER?.includes(fulf.id))
        myAccount.CUSTOMER = this.myCustomers.filter(customer => user?.authorities?.CUSTOMER?.includes(customer.id))
      }
      return myAccount
    },
    async handleAuthDomains () {
      const domainNames = await getAuthDomainNames()
      this.authDomains = domainNames
    },
    hasAbilityToEdit (user) {
      const userRole = user.role.value
      const editableRoles = this.items && this.items.map(item => item.value)
      return editableRoles && editableRoles.includes(userRole)
    },
    async updateUser (row) {
      this.loading.push(row)
      if (this.sortedRows[row].uid === this.myInfo.id) {
        const newPassword = this.currUserPw.new
        const oldPassword = this.currUserPw.old
        const user = this.myInfo
        await putContextsUser(user, oldPassword, newPassword)
        this.editRow(row)
      } else {
        let editedUser = cloneDeep(this.userList).filter(user => user.id === this.sortedRows[row].uid)

        // Get Role Name and Hierarchy Level
        const oldRoleName = editedUser[0].roles[editedUser[0].roles.length - 1]
        const newRoleName = this.sortedRows[row].role.value
        const newRoleLevel = UserLevels[newRoleName]
        const oldRoleLevel = UserLevels[oldRoleName]

        // Adjust chosen user's Permissions
        await adjustUserPermission(editedUser[0], newRoleLevel > oldRoleLevel)
      }
      this.editRow(row)
      this.loading = []
    },
    getAvailableOptions (row) {
      return convertedUser[row.role.value]
    },
    switchVisibility () {
      if (this.newUser.passwordVisible === 'text') {
        this.newUser = { ...this.newUser, passwordVisible: 'password' }
      } else {
        this.newUser = { ...this.newUser, passwordVisible: 'text' }
      }
    },
    switchCurrUserVisibilityNew () {
      this.currUserPwVisible.new = this.currUserPwVisible.new === 'text' ? 'password' : 'text'
    },
    switchCurrUserVisibilityOld () {
      this.currUserPwVisible.old = this.currUserPwVisible.old === 'text' ? 'password' : 'text'
    },
    clearError (errType) {
      if (this.newUser.errors.length > 0) {
        if (this.newUser.errors.include(errType)) {
          this.newUser.errors = this.newUser.errors.filter(item => item === errType)
        }
      }
    },
    getSelectedItem (picked, num) {
      this.sortedRows[num].role = { value: picked, text: UserTypes[picked] }
    },
    toggleUserForm () {
      if (this.showUserForm) {
        this.newUser = { email: '', password: '', role: {}, errors: [], passwordVisible: 'password' }
      }
      this.showUserForm = !this.showUserForm
    },
    async createNewUserFunc () {
      const userRole = cloneDeep(this.newUser.role).split('_')
      const data = {
        email: this.newUser.email + '@' + this.newUser.domain,
        username: this.newUser.email + '@' + this.newUser.domain,
        password: this.newUser.password,
        userType: userRole[0],
        forceAdmin: userRole[1] === 'ADMIN'
      }
      this.loading.push('newUser')

      const user = await createNewUser(data)
      const roleIndex = user.roles.length - 1
      await this.addUserToTable(user, roleIndex)

      this.userList.push(user)
      this.resetForms()
    },
    async addUserToTable (user, roleIndex) {
      const myAccount = this.getAccountName(user, roleIndex)
      let myAccountName = (myAccount.FULFILLER?.[0]?.name || '') +
        (myAccount.FULFILLER?.[0]?.name && myAccount.CUSTOMER?.[0]?.name ? ' / ' : '') +
        (myAccount.CUSTOMER?.[0]?.name || '')
      myAccountName = !myAccountName ? await this.getActiveAccount(user) : myAccountName

      const currItem = {
        email: user?.email,
        password: '********',
        role: { value: user.roles[roleIndex], text: UserTypes[user.roles[roleIndex]] },
        uid: user.id,
        account: myAccountName
      }
      this.rows.push(currItem)
      this.unfilteredRows.push(currItem)
    },
    initNewUser () {
      this.newUser = { email: '', password: '', role: {}, errors: [], passwordVisible: 'password' }
    },
    async getActiveAccount (user) {
      const authorityId = user.authorities?.CUSTOMER?.[0] || user.authorities?.FULFILLER?.[0]
      if (this.allAuthorities[authorityId]) {
        return this.allAuthorities[authorityId].name
      } else {
        const isCustomer = !!user.authorities?.CUSTOMER?.[0]
        if (isCustomer && authorityId && (!this.allAuthorities?.[authorityId] || !this.allAuthorities?.[authorityId]?.fulfillerId)) {
          const authority = await this.getAuthorityName({ id: authorityId, type: 'customers' })
          this.allAuthorities[authorityId] = authority
          return authority.name
        } else if (authorityId && (!this.allAuthorities?.[authorityId])) {
          const authority = await this.getAuthorityName({ id: authorityId, type: 'fulfillers' })
          this.allAuthorities[authorityId] = authority
          return authority.name
        }
        return ''
      }
    },
    resetForms () {
      this.loading = []
      this.initNewUser()
      this.showUserForm = false
    },
    editRow (row) {
      this.editingRow = this.editingRow === row ? null : row
    },

    trashRow (row) {
      trashUser(this.sortedRows[row])
      this.editingRow = null
      const removedRow = this.sortedRows.splice(row, 1)
      this.rows.filter(item => item.uid === removedRow[0].uid)
    },
    getAvailableRoles () {
      let thisUsersRoles = cloneDeep(this.myInfo.roles)
      const adjustableRoles = thisUsersRoles.map(role => roleAbility[role])
      const longestArrayIndex = adjustableRoles && adjustableRoles.map(a => a?.length).indexOf(Math.max(...adjustableRoles.map(a => a?.length)))

      const bestAuthorityList = longestArrayIndex && adjustableRoles[longestArrayIndex]
      this.items = bestAuthorityList && bestAuthorityList.map(role => ({ value: role, text: UserTypes[role], role: role }))
    }
  }
}
</script>
<style>
.intuitive-flex {
  display: flex; justify-content: flex-start; align-items: center; flex-direction: row;
}
.table-cell-double-input {
  display: flex;  width: 45%; margin:  2.5% 2.5% auto 2.5%; float: left;
}
@media all and (max-width: 800px){
  .intuitive-flex {
    flex-direction: column;
  }
}
.mid-space-grid {
  width: 95%; margin: auto;
}
.v-data-table {
  padding: 20px;
}
v-btn { margin: auto 8px; }
</style>
